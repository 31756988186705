<template>
  <div class="patentInfo" :style="{ height: infoHei }">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <div class="updateBox flex-between">
            <div class="type">
              <el-radio-group
                v-model="searchData.use_flag"
                @change="getDataList"
              >
                <el-radio-button label="1">在用</el-radio-button>
                <el-radio-button label="0">停用</el-radio-button>
              </el-radio-group>
              <el-radio-group
                v-model="searchData.drug_type"
                @change="getDataList"
              >
                <el-radio-button label="0">全部</el-radio-button>
                <el-radio-button label="2">院外医嘱</el-radio-button>
                <el-radio-button label="1">透中医嘱</el-radio-button>
              </el-radio-group>
            </div>
            <div class="upButtom">
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('batchLongIerm')"
              >
                <i class="fa fa-plus-circle"></i>批量新增
              </el-button>
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('addLongIerm', '0')"
              >
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </div>
          </div>
          <div class="tables">
            <el-table
              v-loading="dataListLoading"
              :data="tableData"
              class="table-ellipsis"
              border
              size="small"
              max-height="400"
            >
              <el-table-column
                width="90"
                fixed
                prop="dict_advice_type_name"
                label="类别"
              />
              <el-table-column
                width="300"
                prop="advice_content"
                label="医嘱内容"
              />
              <el-table-column width="300" label="循环周期">
                <template #default="scope">
                  <template v-if="scope.row.dict_cycle_loop">
                    <div
                      v-if="
                        scope.row.dict_cycle_loop.cycle === 'every_dialysis'
                      "
                    >
                      每次透析
                    </div>
                    <div
                      v-if="scope.row.dict_cycle_loop.cycle === 'fixed_date'"
                    >
                      每周[{{ cycleFiler(scope.row.dict_cycle_loop.day) }}]
                    </div>
                    <div v-if="scope.row.dict_cycle_loop.cycle === 'frequency'">
                      每隔{{ scope.row.dict_cycle_loop.time }}次透析
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                width="90"
                prop="drug_type_name"
                label="透中用药"
              />
              <el-table-column
                width="90"
                prop="self_provided_name"
                label="个人库存"
              />
              <el-table-column width="90" label="院外用药">
                <template #default="scope">
                  {{ scope.row.is_preopen === 1 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column width="90" prop="use_flag_name" label="状态" />
              <el-table-column width="90" prop="doctor.name" label="开嘱医生" />
              <el-table-column width="160" prop="start_time" label="开嘱时间" />
              <el-table-column width="160" prop="end_time" label="结束时间" />
              <el-table-column prop="address" label="医嘱套" />
              <el-table-column fixed="right" label="操作" width="160">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    v-if="searchData.use_flag === '1'"
                    @click="deatilsClick(scope.row)"
                  >
                    <i class="txIons stop"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    v-if="searchData.use_flag === '1'"
                    @click="modParient(scope.row)"
                  >
                    <i class="txIons modify"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="delParient(scope.row)"
                  >
                    <i class="txIons del"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-scrollbar>
    <BaseDialog
      title="停用"
      :isshow="visibleItem"
      @handleShow="handleShow"
      width="600px"
      top="20%"
    >
      <div class="tongluBox">
        <el-form
          :model="stupData"
          label-width="110px"
          label-position="right"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="停用原因:">
            <el-input
              v-model="stupData.group_name"
              placeholder="请输入停用原因"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visibleItem = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <batchLongIerm
      v-if="isBatchLongIerm"
      ref="batchLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></batchLongIerm>
    <addLongIerm
      v-if="isAddLongIerm"
      ref="addLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></addLongIerm>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick } from 'vue'
import { getConstHei } from '@/utils/tool.js'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import batchLongIerm from './batchLongIerm.vue'
import addLongIerm from './addLongIerm.vue'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'programme',

  components: {
    batchLongIerm,
    addLongIerm,
    BaseDialog,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      searchData: {
        is_preopen: '0',
        use_flag: '1',
        drug_type: '0',
        scene: 'info',
      },
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      dataListLoading: false,
      isBatchLongIerm: false,
      batchLongIermRef: null,
      isAddLongIerm: false,
      addLongIermRef: null,
      stupData: {},
      visibleItem: false,
      saveLoading: false,
      patientId: route.params.id,
    })

    onMounted(() => {
      state.infoHei = getConstHei()
      getDataList()
    })

    const getDataList = async () => {
      state.searchData.patient_id = route.params.id
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      state.dataListLoading = true
      let res = await service.post('/api/advice/list', state.searchData)
      state.dataListLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
      }
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }

    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const openWeight = (type, val) => {
      switch (type) {
        case 'batchLongIerm':
          state.isBatchLongIerm = true
          nextTick(() => {
            state.batchLongIermRef.initData()
          })
          break
        case 'addLongIerm':
          state.isAddLongIerm = true
          nextTick(() => {
            state.addLongIermRef.initData('', val)
          })
          break
      }
    }

    const cycleFiler = (item) => {
      if (!item) return
      let row = []
      item.forEach((el) => {
        if (el === 'monday') {
          row.push('周一')
        }
        if (el === 'tuesday') {
          row.push('周二')
        }
        if (el === 'wednesday') {
          row.push('周三')
        }
        if (el === 'thursday') {
          row.push('周四')
        }
        if (el === 'friday') {
          row.push('周五')
        }
        if (el === 'saturday') {
          row.push('周六')
        }
        if (el === 'sunday') {
          row.push('周日')
        }
      })
      return row.sort().join(',')
    }

    const handleShow = (val) => {
      state.visibleItem = val
    }

    const deatilsClick = (item) => {
      state.stupData.id = item.id
      state.stupData.group_name = ''
      state.visibleItem = true
    }
    const accessItem = async () => {
      state.saveLoading = true
      let res = await service.post('/api/advice/stop', state.stupData)
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.visibleItem = false
        getDataList()
      }
    }
    const modParient = (item) => {
      state.isAddLongIerm = true
      nextTick(() => {
        state.addLongIermRef.initData(item, '0')
      })
    }
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/advice/delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }

    return {
      ...toRefs(state),
      openWeight,
      getDataList,
      accessItem,
      deatilsClick,
      modParient,
      delParient,
      handleShow,
      handleSizeChange,
      handleCurrentChange,
      cycleFiler,
    }
  },
}
</script>

<style scoped lang="scss">
.patentInfo {
  margin-top: 10px;
}
.podBot {
  padding-bottom: 30px;
}
.patInfo {
  width: 220px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 20px;
  margin-right: 32px;
}
.cont-main {
  flex: 1;
}
.updateBox {
  height: 32px;
  margin-bottom: 24px;
  .el-radio-group {
    margin-right: 30px;
  }
}
.tables {
  margin-bottom: 40px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
.tongluBox {
  margin-top: 20px;
}
</style>
